<template>
  <div id="mian">
    <div>
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>分账商户</i>
            <el-select
              v-model="query.keyword"
              filterable
              clearable
              :filter-method="searchMchList"
              @visible-change="unfoldMchList"
              @clear="clearMchList"
              @change="changeMch"
              placeholder="分账商户名称/编号"
              :no-data-text="divisionData=='' ? '无数据':'可搜索'"
            >
              <el-option
                v-for="item in divisionData"
                :key="item.allocMchId"
                :label="item.allocMchName + ' | ' + item.allocMchId"
                :value="item.allocMchId"
              >
              </el-option>
            </el-select>            
          </div>
          <div class="item">
            <i class="jianju">所属商户</i>
            <el-select v-model="query.mchId">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(v, i) in mchDrop"
                :key="i"
                :label="v.mchName"
                :value="v.mchId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <i class="jianju">特约商户</i>
            <el-select v-model="query.specialMch">
              <el-option label="全部" value=""></el-option>
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="itembtn">
            <el-button class="searchBt" @click="getLists">查询</el-button>
          </div>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>分账商户名称</p>
              <p>分账商户编号</p>
            </td>
            <td>
              <p>所属商户</p>
            </td>
            <td>
              <p>总余额(元)</p>
              <p>待清算金额(元)</p>
            </td>
            <td>
              <p>当日收款余额(元)</p>
              <p>收款冻结余额(元)</p>
            </td>
            <td>
              <p>可结算金额(元)</p>
              <p>可结算冻结金额(元)</p>
            </td>
            <td>
              <p>担保账户金额(元)</p>
              <p>风控冻结金额(元)</p>
            </td>
            <td>
              <p>代付权限</p>
              <p>特约商户</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.allocMchName }}</p>
              <p>{{ v.allocMchId }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ (v.totalBalance / 100) | formatMoney }}</p>
              <p>{{ (v.unsettleAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ (v.receiptBalance / 100) | formatMoney }}</p>
              <p>{{ (v.receiptFrozenBalance / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ (v.settleAmount / 100) | formatMoney }}</p>
              <p>{{ (v.settleFrozenAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ (v.guaranteeBalance / 100) | formatMoney }}</p>
              <p>{{ (v.controlAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p v-show="OFFON_EnabledStatus != 'TRADE:ALLOC:ACCOUNTS/ZHSTATUS'"> {{ v.enabledStatus==true ? "已开启" : "已关闭" }}</p>
							<p v-auth="'TRADE:ALLOC:ACCOUNTS/ZHSTATUS'">
                <el-switch
                  v-model="v.enabledStatus"
                  @change="setStatus(v)"
                  active-color="#13ce66"
                >
                </el-switch>
              </p>
              <p v-show="OFFON_EnabledStatus != 'TRADE:ALLOC:ACCOUNTS/ZHSTATUS'"> {{ v.specialMch==true ? "已开启" : "已关闭" }}</p>
							<p v-auth="'TRADE:ALLOC:ACCOUNTS/ZHSTATUS'" style="margin-top: 4px;">
                <el-switch
                  v-model="v.specialMch"
                  @change="setSpecialMch(v)"
                  active-color="#13ce66"
                >
                </el-switch>
              </p>
            </td>
            <td>
              <p>
                <i class="lianjie" @click="getDetail(v)">查询</i>
                <a
                  href="javascript:;"
                  @click="showLists(v)"
                  class="lianjie"
                  style="padding-left: 7px"
                  >明细</a
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        ref="pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getMerchants,
  getMerchantsDetail,
  setAccountsStatus,setAccountsSpecialMch
} from "@/api/trading/subaccount.js";
import { merchantsDrop , DivisionDrop } from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      query: {
        pageNo: 1,
        pageSize: 10,
        mchId: "",
        keyword: "",
        specialMch:"",
      },
      totalNum: 0,
      tabData: [],
      mchDrop: [],
      OFFON_EnabledStatus: 'TRADE:ALLOC:ACCOUNTS/ZHSTATUS',
      divisionData: [], //分账商户名称/编号可选项
      keywordData:{
        allocMchId:"",
        allocMchName:"",
      },
    };
  },
  computed: {
    ...mapState({
      manageDetail: "trading_manage",
    }),
  },
  created() {
    if (this.manageDetail) {
      this.query = this.manageDetail.query;
      this.keywordData = this.manageDetail.keywordData;
    }
    if(JSON.stringify(this.keywordData) != '{}' && this.keywordData != undefined && this.keywordData.allocMchId != ""){
      this.query.keyword = this.keywordData.allocMchName + ' | ' + this.keywordData.allocMchId;
    }
    this.$nextTick(() => {
      this.$refs.pagination.internalCurrentPage = this.query.pageNo;
    });
    this.getLists(false);
    this.getDrop();
    this.$enter(this.$route.path, this.getLists);
    this.listJurisdiction();
  },
  methods: {
    ...mapMutations({
      setManage: "trading_setManage",
    }),
    // 获取列表详情
    getLists(reset = true) {
      if (reset) {
        (this.query.pageNo = 1);
          // (this.query.keyword = this.query.keyword.trim());
      }
      let data = JSON.parse(JSON.stringify(this.query));
      if(this.keywordData != undefined && this.keywordData.allocMchId != ""){
        data.keyword = this.keywordData.allocMchId;
      }
      getMerchants(data).then((res) => {
        if (res.resultStatus) {
          this.tabData = [];
          this.totalNum = res.resultData.totalNum;
          res.resultData.merchants.forEach((v) => {
            let obj = {
              thirdAccountId: v.thirdAccountId,
              mchName: v.mchName,
              allocMchId: v.allocMchId,
              allocMchName: v.allocMchName,
              totalBalance: null,
              settleAmount: null,
              settleFrozenAmount: null,
              guaranteeBalance: null,
              controlAmount: null,
              unsettleAmount: null,
              receiptBalance: null,
              receiptFrozenBalance: null,
              enabledStatus: v.enabledStatus ? true : false,
              specialMch: v.specialMch ? true : false,
            };
            this.tabData.push(obj);
          });
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
        }
      });
    },
    // 查询
    getDetail(value) {
      getMerchantsDetail(value.thirdAccountId).then((res) => {
        if (res.resultStatus) {
          this.$message.success("查询成功");
          let data = res.resultData || {};
          value.enabledStatus = data.enabledStatus ? true : false;
          value.totalBalance = data.totalBalance;
          value.settleAmount = data.settleAmount;
          value.settleFrozenAmount = data.settleFrozenAmount;
          value.guaranteeBalance = data.guaranteeBalance;
          value.controlAmount = data.controlAmount;
          value.unsettleAmount = data.unsettleAmount;
          value.receiptBalance = data.receiptBalance;
          value.receiptFrozenBalance = data.receiptFrozenBalance;
        }
      });
    },
    // 获取下拉
    getDrop() {
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 明细
    showLists(value) {
      this.$router.push({
        name: "refund_sgjs",
        query: {
          id: value.allocMchId,
        },
      });
    },
    //启用、关闭状态
    setStatus(value) {
      this.$confirm("此操作将修改状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            thirdAccountId: value.thirdAccountId,
            enabledStatus: value.enabledStatus,
          };
          setAccountsStatus(data).then((res) => {
            if (!res) {
              value.enabledStatus = value.enabledStatus ? false : true;
            }
          });
        })
        .catch(() => {
          value.enabledStatus = value.enabledStatus ? false : true;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    }, 
    //特约商户
    setSpecialMch(value) {
      this.$confirm("此操作将修改状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            thirdAccountId: value.thirdAccountId,
            specialMch: value.specialMch,
          };
          setAccountsSpecialMch(data).then((res) => {
            if (!res) {
              value.specialMch = value.specialMch ? false : true;
            }
          });
        })
        .catch(() => {
          value.specialMch = value.specialMch ? false : true;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    }, 
    //判断列表按钮是否有权限
		listJurisdiction(){
					const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
           if(_resources=='ALL'){
                this.OFFON_EnabledStatus = 'TRADE:ALLOC:ACCOUNTS/ZHSTATUS';
           }else{
              if(_resources.split(';').indexOf('TRADE:ALLOC:ACCOUNTS/ZHSTATUS') !== -1 ){
                this.OFFON_EnabledStatus = 'TRADE:ALLOC:ACCOUNTS/ZHSTATUS';
              }else{
                this.OFFON_EnabledStatus = "";
              }
           }
		}, 

    // 获取商户下拉列表
    _searchAllMch(value) {
      this.divisionData = [];
      let params = {
        keyword: value,
      };
      DivisionDrop(params).then((res) => {
        if (res.resultStatus) {
          this.divisionData = res.resultData;
          console.log(this.divisionData);
        }
      });
    },
    // 商户下拉列表输入搜索内容时
    searchMchList(val) {
      if (val) {
        let value = val;
        this._searchAllMch(value);
      } else {
        // 搜索内容为空时还原列表
        this._searchAllMch();
      }
    },
    // 商户下拉框展开/收起时触发
    unfoldMchList(e) {
      if (e) {
        //  展开时
        this._searchAllMch();
      } else {
        //  折叠时
        this.divisionData = [];
      }
    },
    // 商户下拉搜索框清空搜索内容时
    clearMchList() {
      this.query.keyword = ""; //清空选中的商户id
      this.keywordData = {};
      this._searchAllMch(); //重新获取所有商户下拉列表
    },
    changeMch(e) {
      this.query.keyword = e;
      let allocMchId  = this.query.keyword;
      console.log( this.query.keyword );
      let result = this.divisionData.filter(e=>e.allocMchId === allocMchId)
      if(result && result.length > 0){
        console.log(result[0])
        this.keywordData = result[0];
      }
    }, 
    
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },   
  },
  beforeDestroy() {
    let data = {
      query: this.query,
      keywordData: this.keywordData,
    };
    this.setManage(data);
  },
};
</script>

<style scoped>

</style>
